<template>
    <div>
       
        <div class="header_common_box">
            <v-header :params="{ title, leftIcon: true }" ></v-header>
        </div>
        <div>
            <div class="info">
                <p >{{maxMsg}}</p>
                <p >筛选结果(<span style="color:red;">{{lastall.length}}</span>)组</p>
            </div>
            <p v-for="(item,index) in this.forlist" :key="index">
                    <a class="number" v-for="(ite,inde) in item" :key="inde">{{ite}}</a>
            </p>
           
        </div>
        
    </div>
   
</template>

<script>
import vHeader from "@/components/header.vue"
export default {
    data(){
        return{
            title:'',
            lastall:[],
            maxMsg:'',
            type:'',
            forlist:[]
        }
    },
    components: {
    vHeader
    },
    mounted(){
       this.title=this.$route.meta.title
        // console.log(this.$route)  
        this.type=this.$store.state.prize.payType
        switch(this.type){
            case 1:
                this.lastall=this.$store.state.prize.Cfourfixed
                this.maxMsg='四定大底'
                break;
            case 2:
                this.lastall=this.$store.state.prize.Cfourshow
                this.maxMsg='四现大底'
                break;
            case 3:
                this.lastall=this.$store.state.prize.Cthreefixed
                this.maxMsg='三定大底'
                break;
            case 4:
                this.lastall=this.$store.state.prize.Cthreeshow
                this.maxMsg='三现大底'
                break;
            case 5:
                this.lastall=this.$store.state.prize.Ctwofixed
                this.maxMsg='二定大底'
                break;
            case 6:
                this.lastall=this.$store.state.prize.Ctwoshow
                this.maxMsg='二现大底'
                break;
           
        }
        // console.log('这是所有结果',this.lastall)
        
        var x = 0;
    let numList = [];
    
    for (var i=0;i<this.lastall.length;i++){
      numList.push(this.lastall[i]);
      x++;
      if (x === 6){
        this.forlist.push(numList);
        numList = [];
        x = 0;
      }
      if (i === this.lastall.length-1)
      {
        this.forlist.push(numList);
        numList = [];
        x = 0;
      }
    }

    },
    

}
</script>

<style lang="less" scoped>

*{
    padding: 0;
    margin: 0;
}
.info{
    background-color: white;
    p{
        font-size: 0.5rem;
    }
    
}

.number{
            display: inline-block;
            width: 1rem;
            font-size: 0.4rem;
            margin: 0.1rem 0.3rem;
}

</style>